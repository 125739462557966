import React from "react";
import {
  BookOpen,
  Home,
  Briefcase,
  Clock,
  Calendar,
  Users,
  Target,
} from "lucide-react";
import ServiceCard from "./components/ServiceCard";
import DurationCard from "./components/DurationCard";
import TestimonialCard from "./components/TestimonialCard";
import { CarouselComponent } from "../Gallery/components/Carousel/Carousel";

function HomePage() {
  const slides = [
    {
      image: "./assets/img1.jpg",
      title: "Majestic Mountains",
      description:
        "Discover the breathtaking beauty of nature's greatest peaks.",
    },
    {
      image: "./assets/img2.jpg",
      title: "Serene Beaches",
      description: "Experience the calming waves and golden sands of paradise.",
    },
    {
      image: "./assets/img3.jpg",
      title: "Urban Adventures",
      description: "Explore the vibrant energy of city life and architecture.",
    },
    {
      image: "./assets/img4.jpg",
      title: "Majestic Mountains",
      description:
        "Discover the breathtaking beauty of nature's greatest peaks.",
    },
    {
      image: "./assets/img5.jpg",
      title: "Serene Beaches",
      description: "Experience the calming waves and golden sands of paradise.",
    },
    {
      image: "./assets/img6.jpg",
      title: "Urban Adventures",
      description: "Explore the vibrant energy of city life and architecture.",
    },
  ];

  return (
    <div className="text-xl bg-gray-50">
      <CarouselComponent slides={slides} />
      {/* Header */}
      <header className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white py-6">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-center">
            Educational Excellence
          </h1>
        </div>
      </header>

      {/* Services Section */}
      <section className="py-16 container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">
          Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ServiceCard
            Icon={BookOpen}
            title="All Board Coaching"
            description="Navigate your career path with expert guidance"
          />
          <ServiceCard
            Icon={Home}
            title="Home Tuition"
            description="Best teachers available here"
          />
          <ServiceCard
            Icon={Briefcase}
            title="Career Coaching"
            description="Navigate your career path with expert guidance"
          />
        </div>
      </section>

      {/* Classes Duration */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">
            Classes Duration
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <DurationCard
              Icon={Clock}
              title="Daily Classes"
              description="Interactive classes"
            />
            <DurationCard
              Icon={Calendar}
              title="Monthly Test Paper"
              description="Tests, Sample Papers & Notes"
            />
            <DurationCard
              Icon={Users}
              title="Doubt Session"
              description="Doubt Solving Sessions"
            />
            <DurationCard
              Icon={Target}
              title="10+ Offline Centre"
              description="100+ Offline centers"
            />
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-16 container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">
          What's Guardianin Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <TestimonialCard
            quote="Shree ji Tuition helped me find clarity in my career and life!"
            author="Sheela Prasad"
          />
          <TestimonialCard
            quote="I couldn't have grown my business without their expert coaching!"
            author="Vikash Yadav"
          />
          <TestimonialCard
            quote="Shree ji Tuition helped me find clarity in my career and life!"
            author="Suresh Singh"
          />
          <TestimonialCard
            quote="I couldn't have grown my business without their expert coaching!"
            author="Abhishek Pandey"
          />
        </div>
      </section>
      {/* About Us */}
      <section className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-center mb-8 text-gray-800">
          About Us
        </h2>
        <div className="mx-auto bg-white rounded-xl p-8 shadow-lg">
          <p className="text-gray-600 leading-relaxed mb-6">
            Shree Ji Tuition ✨📚
            <br />
            Shree Ji Tuition provides expert tutoring services for students from
            LKG to 12th grade across all boards (CBSE, ICSE, ISE, UP Board, and
            other state boards). 🎓 Our goal is to focus on the individual needs
            of students and help them reach a high academic level.
            <br />
            Key Features 📌
            <br />✅ Personalized Attention: One-on-one study sessions.
            <br />✅ Expert Teachers: Experienced teachers for all classes and
            subjects.
            <br />✅ Comprehensive Curriculum: From basic to advanced topics.
            <br />✅ Special Facilities: 🏡 Home tutor services, online/offline
            classes, and study at centers.
            <br />✅ Weekly Practice Sheets: ✍️ Weekly practice sheets provided.
            <br />✅ Monthly Tests: 🏆 Preparation for competitive exams like
            JEE, NEET, CHS, Navodaya, CUET.
            <br /> Availability 🌍
            <br /> Shree Ji Tuition services are available in several major
            cities of Uttar Pradesh, including Azamgarh, Gorakhpur, Deoria,
            Ayodhya, and Akbarpur.
            <br />
            Our Objective 🎯
            <br /> We aim to create a progressive roadmap tailored to the needs
            of each student, making them self-reliant and confident. 📖 Both
            online and offline options available.
            <br />
            📚 For practice, understanding, and success, Shree Ji Tuition is
            your ideal partner! 😊
          </p>
          <div className="space-y-2 text-gray-600">
            Shree Ji Tuition ✨📚
            <br />
            श्री जी ट्यूशन सभी बोर्ड (CBSE, ICSE, ISE, UP Board और अन्य राज्य
            बोर्ड) के लिए LKG से 12वीं कक्षा तक के छात्रों के लिए उत्कृष्ट
            ट्यूशन सेवाएं प्रदान करता है। 🎓 हमारा उद्देश्य छात्रों की व्यक्तिगत
            आवश्यकताओं पर ध्यान केंद्रित करते हुए उन्हें उच्च शैक्षणिक स्तर पर
            पहुँचाना है।
            <br />
            हमारे प्रमुख बिंदु 📌
            <br />✅ व्यक्तिगत ध्यान: एक-एक करके पढ़ाई की सुविधा।
            <br />✅ विशेषज्ञ शिक्षक: सभी कक्षाओं और विषयों के लिए अनुभवी शिक्षक
            उपलब्ध।
            <br />✅ व्यापक पाठ्यक्रम: बेसिक से लेकर एडवांस टॉपिक्स तक।
            <br />✅ विशेष सुविधा: 🏡 घर पर शिक्षक बुलाने, ऑनलाइन/ऑफलाइन क्लास
            और सेंटर्स पर पढ़ाई की सुविधा।
            <br />✅ साप्ताहिक प्रैक्टिस शीट: ✍️ प्रत्येक सप्ताह अभ्यास पत्रक
            उपलब्ध।
            <br />✅ मासिक टेस्ट: 🏆 JEE, NEET, CHS, नवोदय, CUET जैसी प्रतियोगी
            परीक्षाओं की तैयारी।
            <br />
            उपलब्धता 🌍
            <br />
            श्री जी ट्यूशन की सेवाएं उत्तर प्रदेश के कई प्रमुख शहरों जैसे
            आजमगढ़, गोरखपुर, देवरिया, अयोध्या और अकरनपुर में उपलब्ध हैं।
            <br />
            हमारा उद्देश्य 🎯
            <br />
            छात्रों की आवश्यकता के अनुसार प्रोग्रेसिव रोडमैप तैयार करके उन्हें
            आत्मनिर्भर और आत्मविश्वासी बनाना।
            <br />
            📖 ऑनलाइन और ऑफलाइन दोनों विकल्प उपलब्ध हैं।
            <br />
            📚 अभ्यास, समझ और सफलता के लिए श्री जी ट्यूशन आपका आदर्श साथी! 😊
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
