import React from "react";
import { Mail, Phone, MapPin } from "lucide-react";
import { useState } from "react";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    mobileno: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      console.log(formData);

      const response = await fetch(
        "https://api.shreejituition.com/apiController.php?action=save-contactus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0YXR2YWNhcmUiLCJleHAiOjE3MjczNzI5NzAsImlhdCI6MTcyNzM0NDE3MH0.3PdD28ZhWLpd6bfzmL3vD40JhwQWX6Xdwtxs20IFLAvqT3FkNCs5WJMepfcAsanZL4jISbqkVV4vUX2lUaXaOA",
          },

          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      console.log(responseData);
      if (responseData.status === "success") {
        setSuccessMessage("Message sent successfully!");
        setFormData({ name: "", mobileno: "", email: "", message: "" });
      } else if (responseData.status === "error") {
        const errors = [];
        if (responseData.errors.name) errors.push(responseData.errors.name);
        if (responseData.errors.mobileno)
          errors.push(responseData.errors.mobileno);
        if (responseData.errors.email) errors.push(responseData.errors.email);
        if (responseData.errors.message)
          errors.push(responseData.errors.message);
        setErrorMessage(
          <ul className="text-red-500">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      setErrorMessage(
        error.message || "Something went wrong. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="space-y-8">
            <h2 className="text-3xl font-bold text-gray-900">Get in Touch</h2>
            <p className="text-gray-600">
              Have questions? We'd love to hear from you.
            </p>

            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-indigo-600" />
                <a href="tel:8009657788">
                  <span>+9180096-57788</span>
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <Mail className="h-5 w-5 text-indigo-600" />
                <a
                  href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=shreejihometution@gmail.com"
                  className="hover:text-yellow-400 pointer transition-colors"
                >
                  shreejihometution@gmail.com
                </a>{" "}
              </div>
              <div className="flex items-center space-x-3">
                <MapPin className="h-5 w-5 text-indigo-600" />
                <span>Budhanpur , Azamgarh (Uttar Pradesh)</span>
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full max-w-md bg-white shadow-lg rounded-lg p-6"
          >
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Contact Us
            </h2>

            {successMessage && (
              <div className="bg-green-100 text-green-700 p-3 rounded mb-4">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
                {errorMessage}
              </div>
            )}

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring focus:ring-blue-300"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="mobileno"
              >
                Mobile No
              </label>
              <input
                type="number"
                id="mobileno"
                name="mobileno"
                value={formData.mobileno}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring focus:ring-blue-300"
                rows="5"
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 disabled:bg-gray-400"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
