import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const NavLinks = ({ orientation = "horizontal", closeMenu }) => {
  const [activeLink, setActiveLink] = useState("Home");
  const links = [
    { link: "Home", url: "/" },
    { link: "Courses", url: "/Courses" },
    { link: "Faculty", url: "/Faculty" },
    { link: "Gallery", url: "/Gallery" },
    { link: "Contact", url: "/Contact" },
    { link: "Career", url: "/Career" },
  ];

  const navigate = useNavigate();

  const handleLinkClick = (item) => {
    setActiveLink(item?.link);
    navigate(item?.url);
    if (closeMenu) {
      closeMenu(false);
    }
  };
  return (
    <>
      {links.map((item, index) => (
        <div onClick={() => handleLinkClick(item)} id={index} key={index}>
          <span
            id={`span_${index}`}
            key={index}
            className={`text-red-800 hover:text-indigo-600 pointer  ${
              activeLink === item?.link
                ? "text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_50%)]"
                : "text-gray-600"
            }  font-medium transition duration-150 ease-in-out
              ${orientation === "vertical" ? "block py-2" : ""}`}
          >
            {item?.link}
          </span>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
